import admin from "./admin"
import company from "./company"
import activiti from "./activiti"
import subs from "@@/lang/en/route"

export default {
  ...admin,
  ...company,
  ...activiti,
  ...subs,
}

export default {

  // 用户管理

  user: {
    placeholder: {
      search: "用户名/电话号码",
    },
    datagrid: {
      label: {
        Repassword: "确认密码",
        Password: "密码",
        BusinessObjects: "业务数据",
        DataScope: "数据范围",
        BusinessField: "字段",
        Avatar:"头像",
        SetReaded:"全部已读"
      },
      title: {

      },
      message: {
        create: {
          success: "用户创建成功",
          fail: "用户创建失败",
        },
        update: {
          success: "用户更新成功",
          fail: "用户更新失败",
        },
        delete: {
          confirm: "确认删除用户?",
          type: "警告",
          canceled: "已取消删除",
          success: "用户删除成功",
          fail: "用户删除失败",
        },
        password: {
          length: "密码必须六位以上",
          required: "请输入密码",
          repassword: "请输入确认密码",
          consistency: "两次输入密码不一致",
          reset: {
            confirm: "是否重置密码？重置后不可恢复!",
            canceled: "已取消重置密码",
            success: "用户重置密码成功",
            fail: "用户重置密码失败",
          },
          newpassword: {
            success: "用户修改密码成功",
            fail: "用户修改密码失败",
          }
        },
        mobile: {
          rule: "手机号有误",
          unique: "该手机号已经存在",
        },
        username: {
          required: "请输入账号",
          rule: "不符合要求",
          chinese: "不能用中文",
          unique: "该账号已经存在",
        }
      },
      row: {


      },
      button: {
        password: "密码",
        menu: "菜单",
        role: "角色",
        data: "数据",
      },
      dialog: {
        title: {
          create: "创建用户",
          update: "编辑用户",
          password: "密码",
          menu: "菜单",
          role: "角色",
          business: "业务数据",
          data: "业务数据",
        }
      },


    }
  },
  staff: {
    datagrid: {
      label: {
        RealName: "姓名",
        Company: "组织",
        Organization: "部门",
        Position: "职务",
        AssignTo:"指派"
      },
      title: {

      },
      message: {
        realName: {
          required: "请填写真实姓名"
        },
        organization: {
          required: "请选择所属部门"
        },
        position: {
          required: "请选择职务"
        }
      }
    }
  },

  organization: {
    datagrid: {
      label: {

      },
      dialog: {
        title: {
          create: "新增部门",
          update: "编辑部门",
        }
      },
    }
  },
  position: {
    datagrid: {
      label: {
        Organization: "部门"
      },
      dialog: {
        title: {
          create: "新增职务 ",
          update: "编辑职务",
          menu: "菜单",
          role: "角色",
          business: "业务数据",
          data: "业务数据",
        }
      },
    }
  },
  object: {
    placeholder: {
      search: "业务对象名称",
    },
    datagrid: {
      label: {
        ClassName: "类名",
        Rules: "规则",
        Company: "公司",
        Organization: "部门",
        User: "用户",
        Field: "字段",
        BusinessObjects: "业务数据",
        DataScope: "数据范围",
        BusinessField: "字段"
      },
      title: {
        Fields: "字段"
      },
      message: {
        create: {
          success: "业务对象创建成功",
          fail: "业务对象创建失败",
        },
        update: {
          success: "业务对象更新成功",
          fail: "业务对象更新失败",
        },
        delete: {
          confirm: "确认删除业务对象?",
          type: "警告",
          canceled: "已取消删除",
          success: "业务对象删除成功",
          fail: "业务对象删除失败",
        }

      },

      username: {
        required: "请输入账号",
        rule: "不符合要求",
        chinese: "不能用中文",
        unique: "该账号已经存在",
      }
    },
    row: {


    },
    button: {
      password: "密码",
      menu: "菜单",
      role: "角色",
      data: "数据",
    },
    dialog: {
      title: {
        create: "创建业务对象",
        update: "编辑业务对象",

        business: "业务数据",
        data: "业务数据",
      }
    },


  },
  url: {
    placeholder: {
      search: "URL,分组",
    },
    datagrid: {
      label: {
        Group: "分组",
        Action: "动作"

      },
      message: {
        name: "请输入名字",
        order: "请输入顺序",
        url: "请输入URL",
        delete: {
          confirm: "确认删除URL?",
          type: "警告",
          canceled: "已取消删除",
          success: "URL删除成功",
          fail: "URL删除失败",
        },
      },
      title: {

      },
      button: {
        auths: "查看授权"
      }
    }
  },
  webmodule: {
    placeholder: {
      search: "分组名称",
    },
    datagrid: {
      label: {
        Parent: "上级",

      },
      message: {
        delete: {
          confirm: "确认删除URL分组?",
          type: "警告",
          canceled: "已取消删除",
          success: "URL分组删除成功",
          fail: "URL分组删除失败",
        },
      },
      title: {

      },
      button: {
        addurl: "添加URL"
      }
    }
  },
  menu: {
    datagrid: {
      placeholder: {
        icon: "点击选择图标"
      },
      label: {
        Hide: "不显示",
        Icon: "图标",
        Target: {
          label: "窗口",
          default_: "默认",
          new_: "新窗口",
          parent_: "父窗口"
        },
        Absolute: "绝对路径",


      }
    }
  },
  server: {
    config: {
      role: {
        business: "业务角色树",
        system: "系统角色树"
      },
      menu: {
        business: "业务菜单树",
        system: "系统菜单树"
      },
      url: {
        unchecked: "未匹配的URL规则"
      },
      password: { 
        provide: "默认密码"
      },
      captcha: {
        switch: "验证码开关"
      },
      posboundorg: {
        switch: "职务与部门绑定"
      },
      log: {
        threshhold: "日志数量"
      },
      erp: {
        audit: {
          rule: {
            salesorder: "销售单审核",
            purchaseorder: "采购单审核",
            transferorder: "调拨单审核",
            inboundbatch: "入库单审核",
            outboundbatch: "出库单审核",
          }
        }
      },
      attachment: {
        chunkSize: '附件分块'
      }
    }
  },
  role: {
    datagrid: {
      button: {
        url: "URL"
      },
      dialog: {
        title: {
          url: "URL权限"
        }
      },
      transfer: {
        title: {
          all: "全部",
          selected: "已选"
        }
      }
    }
  },
  log: {
    placeholder: {
      type: "在这里输入日志类型",
      range: {
        separator: "至",
        start: "开始日期",
        end: "结束日期"
      }
    },
    label: {
      type: "日志类型",

    }

  }



};

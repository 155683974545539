import { App, createApp } from 'vue';
import Entry from './Entry.vue';
import { setupStore } from '@/store';
import { createRouter, createWebHashHistory } from "vue-router";
import { loginRoutes } from "@/router";
import { useAppStoreHook } from '@/store/modules/app';
import { useUserStoreHook } from '@/store/modules/user';
import { useMenuStoreHook } from '@/store/modules/menus';
import { getSystemInfoApi } from '@/api/admin/config';
import { getLock } from './utils/lock';
import "@/utils/rem.ts" 

// highlight 样式 组件 依赖
import 'highlight.js/styles/atom-one-dark.css';
import hljs from 'highlight.js/lib/core';
import xml from "highlight.js/lib/languages/xml";



// 本地SVG图标
import 'virtual:svg-icons-register';

// 国际化
import i18n from '@/lang';

// 样式
import 'element-plus/theme-chalk/dark/css-vars.css';
import '@/styles/index.scss';

import { getElementLabelLine } from 'element-tree-line';



const app = createApp(Entry);

setupStore(app);

//========================================================================================
/**
 * 全局事件总线注册   目前只在IM中使用
 * mitt库代码非常小
 */
import mitt from "mitt"
const bus = mitt();
app.provide("bus", bus) //要使用app.provide 或者在 setup 中使用provide





/**
 * main.js for activiti bpmnjs
 */
import {
    createDiscreteApi,
    create,
    NAlert,
    NColorPicker,
    NConfigProvider,
    NMessageProvider,
    NDialogProvider,
    NButton,
    NButtonGroup,
    NTag,
    NCollapse,
    NCollapseItem,
    NDataTable,
    NPopover,
    NDrawer,
    NDrawerContent,
    NModal,
    NCode,
    NForm,
    NFormItem,
    NInput,
    NInputNumber,
    NRadio,
    NRadioGroup,
    NCheckbox,
    NCheckboxGroup,
    NSelect,
    NSwitch
  } from 'naive-ui'
  
  const naive = create({
    components: [
        NAlert,
      NColorPicker,
      NConfigProvider,
      NMessageProvider,
      NDialogProvider,
      NButton,
      NButtonGroup,
      NTag,
      NCollapse,
      NCollapseItem,
      NDataTable,
      NPopover,
      NDrawer,
      NDrawerContent,
      NModal,
      NCode,
      NForm,
      NFormItem,
      NInput,
      NInputNumber,
      NRadio,
      NRadioGroup,
      NCheckbox,
      NCheckboxGroup,
      NSelect,
      NSwitch
    ]
  })
  
  const { message } = createDiscreteApi(['message'])
  window.__messageBox = message
  
  import LucideIcon from '@/bpmnjs/components/common/LucideIcon.vue'
  import EditItem from '@/bpmnjs/components/common/EditItem.vue'
  import CollapseTitle from '@/bpmnjs/components/common/CollapseTitle.vue'
  
  app.use(naive)
  app.component('LucideIcon', LucideIcon)
  app.component('EditItem', EditItem)
  app.component('CollapseTitle', CollapseTitle)


//==============================================================================

import FcDesigner from "@form-create/designer"
import formCreate from '@form-create/element-ui'
import ElementPlus from 'element-plus'
import AttachmentUpload from "@/components/Upload/AttachmentUpload.vue"
import CheckedUser from "@/components/activiti/CheckedUser.vue"

// 全局注册ElementLabelLine 树形组件的画线
const ElementLabelLine:any = getElementLabelLine(h);
app.component(ElementLabelLine.name, ElementLabelLine);

app.use(ElementPlus)

//// 流程编辑器组件
app.use(formCreate)
app.use(FcDesigner);

app.component("attachment",AttachmentUpload)
app.component("checkedUser",CheckedUser)



//===============================================================================
// 在线聊天IM
import contextMenuContact from "@/im/directives/contextmenu"


app.directive('contextmenu_contact', contextMenuContact);

//===============================================================================
//=========//回车后的光标顺序 tab 目前只有流程编辑页面在用
app.directive('enter-tab', function(el) {
    el.addEventListener('keydown', (e)=>{
        if (e.key==='Enter') {
            if(e.target.nodeName==='BUTTON') return
            e.preventDefault();
            const formElements = [...el.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            )].sort((a,b)=>a.tabIndex - b.tabIndex);
            const index = formElements.indexOf(e.target);
            formElements[index+1]?.focus();
        }
    });
});
/**
 * 这里注册的全局指令有  highlight contextmenu enter-tab 需要充分用起来
 * naiveui 是在流程编辑器里使用，需要优化掉
 */

//高亮显示 流程里的XML代码

hljs.registerLanguage("xml", xml);
hljs.configure({ignoreUnescapedHTML: true});

app.directive('highlight', function(el) {
    const blocks = el.querySelectorAll("pre code");
    blocks.forEach((block:any) => {
        hljs.highlightElement(block);
        block.innerHTML = `<ol><li>${block.innerHTML.replace(/\n/g,
        `</li><li class="line">`)}</li></ol>`;
    });
});

//========================================================================================
/**
 * 获取系统标题等显示信息
 */
const menuStore = useMenuStoreHook();
const appStore = useAppStoreHook();
const userStore = useUserStoreHook();

getLock().then(unlock=>{
    getSystemInfoApi().then(data=>{
        if (data) {
            appStore.setSystemInfo(data)
        } else {
            const systeminfo = {
                title: "Aries IT System",
                name: "Aries IT System",
                copyRight: " Copyright © 2021 - 2024 aries.com All Rights Reserved. Aries技术版权所有",
                icp: "ICP备*******号-3",
                license: "公安网备*******号-3",
                hasLogo: false
            }
            appStore.setSystemInfo(systeminfo)
        }
        document.title = appStore.systeminfo.title
    }).finally(()=>{
        unlock()
      })
   
  })



/**
 * 获取系统菜单，设置路由信息
 */

if (null == appStore.getSession()) {
    console.log("main.js not logged in")
    setupRouter(app, true);
} else {
    console.log("main.js has logged in. init menus")
    userStore.getInfo()
    //当前用户信息在storage里
    menuStore.initMenus()
    .then(()=>{
        setupRouter(app, false);
    })
    .catch(()=>{
        setupRouter(app, true);
    })
}

function setupRouter(app: App<Element>, forLogin:boolean) {
    console.log("setup router for login - ", forLogin)
    const router = createRouter({
        history: createWebHashHistory(),
        routes: forLogin? loginRoutes() :menuStore.routes, //加载菜单路由
        // 刷新时，滚动条位置还原
        scrollBehavior: () => ({ left: 0, top: 0 }),
        linkActiveClass: "active"
    });
    app.use(router).use(i18n);
    router.isReady().then(() => {
    
        console.log("so inject [router] to appStore");
        appStore.setupRouter(router).then(() => {
            if(forLogin) appStore.logout();
            getLock().then(unlock=>{
                if (appStore.systeminfo) {
                    app.mount('#app')
                } else {
                    console.error("without systemInfo!")
                }
                unlock();
            });
            
        });
        //等待路由菜单都准备就绪后挂载vue
    });
}

export default {
  Messages:"消息",
  Machines:"机器",
  Abnormals:"异常",
  TodaySummary:"今日营收汇总",
  Region:"区域",
  Quantity:"数量",
  Replenish:"补货数量",
  Inventory:"当前库存",
  Amount:"收款金额",
  AmountDiff:"收款金额差值",
  GoodsDiff:"商品计数差值",
  AssignTo:"指派",
  product: {
    category: {
      parent: "上级分类"
    }
  },
  warehouse:{
    Stock:"机器库存"
  },
  machine: {
    status: {
      Abnormal:"异常",
      Work:"正常",
      All:"全部"
    },
    City:"城市",
    Stocker:"补货员",
    Manager:"管理员",
    MachineName:"机器名称"
  },
  plan: {
    WorkDay:"工作日",
    WorkPlan:"工作路径",
    View:"查看/管理"
    
  }

}

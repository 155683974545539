import admin from "./admin"
import activiti from "./activiti"
import erp from "./erp"
import device from "./device"
import subs from "@@/lang/zhCN/role"

export default {
  ...admin,
  ...activiti,
  ...erp,
  ...device,
  ...subs
}

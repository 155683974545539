// 路由

export default {
  Admins: "Admins",
    SuperAdmin: "Super",
    Actuators: "Actuators",
    Systems: "Systems",
    Organizations: "Organizations",
    Login: "Login",
    Businesses: "Businesses",
    Departments: "Departments",
}

export default {
    rule: {
        product: {
            productNo:"No. is required",
            unitPrice: "Unit price is required",
            unitCost: "Unit cost is required",
            category: "Category is required",
            presales: "Presales is required",
            warehouse: "Warehouse is required"
        }
    },
    placeholder: {
        NameOrNo: "Name or No."
    }
}

export default {
    Activities: "Activities",
    Definitions: "Definitions",
    Instances: "Instances",
    Tasks: "Tasks",
    HistoricInstances: "HistoricInstances",
    HistoricTasks: "HistoricTasks",
    Designers: "Designers",
    Forms: "Forms",
    OAForms: "OAForms",
    StaffForms: "StaffForms",
    MyForms: "MyForms",
    Todos: "Todos",
    My: "My",
    Claims: "Claims",
    Waitings: "Waitings",
    Histories: "Histories",
    
  }
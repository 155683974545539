import request from '@/utils/request';
import type { PageContent } from '@/api/types';
import type { ICheckResult, IMobileMap, IPasswordMap, IUser, IUserNameMap } from './types';
import { checkApi } from '@/api/auth';
import { ICheckedApi } from '@/api/auth/types';
import {appendToken} from "@/utils/request";



export function listApi(queryParams: Object): Promise<PageContent> {
    return request({
        url: "/admin/users",
        method: 'get',
        params: queryParams
    });
}
/**
 * @param data 
 * @returns id for data
 */
export function createOrUpdateApi(data: IUser): Promise<number> {
    return request({
        url: "/admin/users",
        method: 'post',
        data: data
    });
}
export function uploadAvatarApi(id: number,data:any) {
    return request({
        url: `/admin/users/${id}/avatar`,
        method: 'post',
        transformRequest: [function(data, headers) {
            // 去除post请求默认的Content-Type
            delete headers['Content-Type']
            return data
          }],
        headers:  { enctype: 'multipart/form-data' },
        data: data,
    });
}
export function checkUserNameApi(val: IUserNameMap): Promise<ICheckResult> {
    return request({
        url: "/admin/users/checkusername",
        method: 'get',
        params: val
    });
}
export function checkMobileApi(val: IMobileMap): Promise<ICheckResult> {
    return request({
        url: "/admin/users/checkmobile",
        method: 'get',
        params: val
    });
}
export function getApi(id: number): Promise<IUser> {
    return request({
        url: `/admin/users/${id}`,
        method: 'get',
    });
}
export function checkDeleteApi(id:number) :Promise<Boolean> {
    const api:ICheckedApi = {
        url: `/admin/users/${id}`,
        method: 'DELETE',
    }
    return checkApi(api);
}
/**
 * 物理删除，需要重构
 * @param id 
 * @returns 
 */
export function deleteApi(id: number): Promise<boolean> {
    return request({
        url: `/admin/users/${id}`,
        method: 'delete',
    });
}
/**
 * 修改用户自己的密码
 */
export function savePasswordApi(data: IPasswordMap): Promise<boolean> {
    return request({
        url: '/admin/users/password',
        method: 'post',
        data: data
    });
}

/**
 * 管理员重置用户密码
 * @param id 
 * @returns 
 */
export function resetPasswordApi(id: number): Promise<boolean> {
    return request({
        url: `/admin/users/${id}/resetpassword`,
        method: 'post',
    });
}
/**
 * 超级管理员直接修改用户密码
 * @param id 
 * @returns 
 */
export function newPasswordApi(id: number,data: IPasswordMap): Promise<boolean> {
    return request({
        url: `/admin/users/${id}/newpassword`,
        method: 'post',
        data: data
    });
}
export function changeUserNameApi(data: IUserNameMap): Promise<boolean> {
    return request({
        url: `/admin/users/setname`,
        method: 'post',
        data: data
    });
}
export function changeMyUserNameApi(data: IUserNameMap): Promise<boolean> {
    return request({
        url: `/admin/users/setmyname`,
        method: 'post',
        data: data
    });
}
export function getAvatarURL(id:number): string {
    return appendToken(`/users/${id}/avatar`);
}
export function getMyAvatarURL(): string {
    return appendToken(`/avatar`);
}
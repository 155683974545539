import Spucategories from "@@/shop/views/spucategories.vue";

export default {

    SHOP:"Shop",
    SPUCategories:"Categories",
    SPUTags:"Groups",
    SPUProperty:"Properties",
    SPU:"Spus",
    SKU:"Products"

  }
export default {
  Dashboard: 'Dashboard', 
    Login: "Login",
    Messages: "Messages",
    Navigator: "Navigator",
    Admins: "Admins",
    Passwords: "Passwords",
    Auths: "Auths",
    Users: "Users",
    Menus: "Menus",
    Roles: "Roles",
    Urls: "Urls",
    UrlModules: "UrlModules",
    ActionLogs: "ActionLogs",
    AuthLogs: "AuthLogs",
    Configs: "Configs",
    SystemConfig: "SystemConfigs",
    BusinessConfig: "BusinessConfigs",
    Dictionary: "Dictionay",
    TagWords: "TagWords",
    Cities: "Cities",
    RemoteAPI: "RemoteAPI",
    Modules: "Modules",
    Organizations: "Organizations",
    Companies: "Companies",
    Departments: "Departments",
    Positions: "Positions",
    Staffs: "Staffs",
    Objects: "Objects",
    Testers: "Testers",
    Businesses: "Businesses",
    Activities: "Workflows",
    Definitions: "Definitions",
    Instances: "Instances",
    Tasks: "Tasks",
    Designers: "Designers",
    Forms: "Forms",
    OAForms: "OAForms",
    StaffForms: "StaffForms",
    MyForms: "MyForms",
    Todos: "Todos",
    My: "My",
    Waitings: "Waitings",
    Claims: "Claims",
    Histories: "Histories",
    Tools: "Tools",
    ApiDocs: "API-Docs",
    H2: "H2 DB",
  Onlines: "在线用户",
  ArticleManagement: "文章管理",
  ArticleCategories: "文章分类",
  Articles: "文章列表",
  Any: "用户菜单",

}
export default {

    4001:"没有登录信息",
    4002:"非法用户访问",
    4003:"非员工访问",
    4004:"员工不存在：{a0}",

    6001:"没有设置业务数据权限",
    6002:"没有此数据权限!"
   
}
export default {
  Messages:"Messages",
  Machines:"Machines",
  Abnormals:"Abnormals",
  TodaySummary:"Today Summary",
  Region:"Region",
  Quantity:"Quantity",
  Replenish:"Replenish",
  Inventory:"Inventory",
  Amount:"Amount",
  AmountDiff:"Amount Diff",
  GoodsDiff:"Goods Diff",
  AssignTo:"Assign To",
  product: {
    category: {
      Parent: "Parent"
    },
    Warehouse:"Warehouse",
    Stock:"Stock",
    Product: "Product"
  },
  warehouse:{
    Stock:"Machine's Stock"
  },
  machine: {
    status: {
      Abnormal:"Abnormal",
      Work:"Work",
      All:"All"
    },
    City:"City",
    Stocker:"Stocker",
    Manager:"Manager",
    MachineName:"Machine Name"
  },
  plan: {
    WorkDay:"Work Day",
    WorkPlan:"Work Plan",
    View:"View&Edit"
  }

}

export default {

    7001: "没有设置城市信息",
    7002: "时区信息不正确",
    7003: "工作计划没有工作日信息",
    7004: "工作日不匹配",
    7005: "当前工单未结束",
    7006: "非本人的工作计划",
    7007: "记录已添加",
    7008: "还有{a0}台机器未收款",
    7009:"{a0}库存不足, 差额为:{a1}",
   
}
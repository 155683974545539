import Applications from "@@/machine/views/applications.vue";

export default {
  PRICECONFIGS: "设置",
  BRANDS: "品牌",
  DEWU: "得物",
  GONGXIE: "共鞋",
  STOCKX: "绿叉",
  BIDS: "比价",
  Config1: "比价设置",
  Config2: "上架设置",
  Config3: "Token设置",
  Brands: "品牌",
  Sizes: "尺码管理",
  DewuSpus: "得物商品",
  DewuSkus: "得物商品详情",
  StockxSpus: "绿叉商品",
  StockxSkus: "绿叉商品详情",
  PriceTasks: "绿叉比价",
  TaskDetails: "绿叉任务明细",
  PriceDetails: "绿叉明细",
  PriceTasks2: "GOAT比价",
  TaskDetails2: "GOAT任务明细",
  PriceDetails2: "GOAT明细",
  GongxieConfig: "共鞋配置",
  GongxieTasks: "共鞋任务",
  GongxieDetails: "共鞋明细",
  GongxieDatas: "共鞋数据",
  GoatTasks: "品牌任务",
  GoatDetails: "品牌明细",
  GoatDatas: "品牌数据",
  GoatPrices:"GOAT价格",
  DewuTasks: "得物任务",
  DewuDetails: "得物明细",
  DewuDatas: "得物数据",
  StockxTasks: "绿叉任务",
  StockxDetails: "绿叉明细",
  StockxDatas: "绿叉数据",
  StockxPrices: "绿叉价格",

  DELETE: "下架",
  ORDER: "售后",
  FOLLOW: "跟价",


  DeleteTasks: "绿叉下架",
  DeleteDetails: "绿叉下架明细",
  DeleteTasks2: "GOAT下架",
  DeleteDetails2: "GOAT下架明细",

  OrderTasks: "绿叉售后",
  OrderDetails: "绿叉售后明细",
  OrderTasks2: "GOAT售后",
  OrderDetails2: "GOAT售后明细",

  FollowTasks: "绿叉跟价",
  FollowDetails: "绿叉跟价明细",
  FollowTasks2: "GOAT跟价",
  FollowDetails2: "GOAT跟价明细",



}
import { createI18n } from 'vue-i18n';
import { useAppStoreHook } from '@/store/modules/app';

// 本地语言包
import zhCn from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";

//bpmn 语言包 
import zh_CN from '@/bpmnjs/i18n/zh_CN'
import en_US from '@/bpmnjs/i18n/en_US'

import aries_CN from "@/lang/zhCN";
import aries_en from "@/lang/en";

import sub_CN from "@@/lang/zhCN";
import sub_en from "@@/lang/en";

export const messages: Record<string, any> = reactive({
  'zh-cn': {
    ...zhCn,
    ...zh_CN,
    ...aries_CN,
    ...sub_CN
  },
  'en': {
    ...en,
    ...en_US,
    ...aries_en,
    ...sub_en
  }
});

export const defaultLang = 'zh-cn'

const language = useStorage("language", defaultLang);

function loadLocaleMessages() {
  const locales = import.meta.glob('./package/*.ts', { eager: true, import: 'default' });

  Object.keys(locales).forEach(key => {
    const matched = key.match(/(\.\/package\/)([A-Za-z0-9-_]+)\.ts/i)
    if (matched && matched.length > 1) {
      const locale = matched[2]
      messages[locale] = { ...messages[locale], ...locales[key] as object };
    }
  })
  const localesCustom = import.meta.glob('./package/custom/*.ts', { eager: true, import: 'default' });

  Object.keys(localesCustom).forEach(key => {
    const matched = key.match(/(\.\/package\/custome\/)([A-Za-z0-9-_]+)\.ts/i)
    if (matched && matched.length > 1) {
      const locale = matched[2]
      messages[locale] = { ...messages[locale], ...locales[key] as object };
    }
  })
  console.log("i18n messages:", messages)
  return messages
}

const i18n = createI18n({
  legacy: false,
  locale: language.value,
  messages: loadLocaleMessages(),
  globalInjection: true,   //进行全局依赖
});

export default i18n;

export default {
  status: {
    WORK:"正常",
    DISCARD:"报废"
  },
  product: {
    Name:"产品名称",
    Unit:"库存单位",
    category: {
      Parent: "上级分类"
    },
    Warehouse:"仓库",
    Stock:"库存",
    Product: "产品"

  },
  warehouse: {
    Region:"区域仓库",
    Name:"仓库名称",
    Code:"仓库编码",
    BatchNo:"批次号",
    InboundType:"入库类型",
    Quantity:"数量",
    InboundTime:"入库时间",
    Warehouse: "仓库",
    OrderNo:"单号",
    WarehouseIn: "入仓",
    WarehouseOut: "出仓"
  }

}

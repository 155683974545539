export default {
  tagsView: {
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close Others",
    closeLeft: "Close Left",
    closeRight: "Close Right",
    closeAll: "Close All",
  },



  // 登录页面
  login: {
    title: 'Aries IT System',
    username: 'Username',
    password: 'Password',
    login: 'Login',
    capsLock: 'Caps lock is On',
    verifyCode: 'VerifyCode',
    message: {
      username: {
        required: 'username is required',
        valid: 'username is not valid'

      },
      password: {
        required: 'password is required',
        min: 'password length must be at least 6',
        valid: 'password is not valid'
      }
    }
  },
  // 导航栏
  navbar: {
    dashboard: 'Dashboard',
    logout: 'Logout',
    document: 'Document',
    gitee: 'Gitee',
    settings: 'Settings'
  },
  datagrid: {
    loading: "Loading...",
    label: {
      Admin: "Admin",
      Name: "Name",
      ShortName:"Short",
      Title: "Title",
      Content: "Content",
      Description:"Description",
      Username: "Username",
      Mobile: "Mobile",
      Email: "Email",
      Creation: "Creation",
      Modification: "Modification",
      Expiration: "Expiration",
      Status: "Status",
      Action: "Actions",
      Search: "Search",
      Selection: "Selection",
      OK: "OK",
      Save: "Save",
      Cancel: "Cancel",
      Back: "Back",
      Sort: "Sort",
      Reset:"Reset",
      Version:"Version",
      Attachment: "Attachment",
      Reload:"Reload",
      Auth: "Auth",
      Submit: "Submit",
      Enabled:"Enabled",
      Disabled:"Disabled",
      picker: {
        date: {
          last_week:"last_week",
          last_month:"last_month",
          last_3_month:"last_3_month"
        }
      }
    },
    title: {
      warning: "Warning"
    },
    placeholder: {
      Selection: "Selection",
      date_picker: "Pick date",
      search:"enter heer",
      input: "input heer"

    },
    message: {
      create: {
        success: "Create Success",
        fail: "Create Fail",
      },
      update: {
        success: "Update Success",
        fail: "Update Fail",
      },
      delete: {
        confirm: "Confirm Deletion?",
        type: "Warning",
        canceled: "Deletion Canceled",
        success: "Deletion Success",
        fail: "Deletion Fail",
        cascades1: "Cascading Deletion[",
        cascades2: "] Continue?",
        warning: "Will delete permanantly, continue?"
      },
      rule: {
        number: "invalid",
        name: "name is required",
        short:"short is required",
        required: "enter heer",
        letters:"letters only",
        format: "format error"
      },
      upload: {
        format:"only accept formats: jpg,png",
        limit10M:"exceeding size of 10M",
        success: "upload success",
        fail: "upload fail"
      }
      


    },

    dialog: {
      title:{
        Create: "Create",
        Update: "Update",
        Menu: "Menu",
        Role: "Role",
        Business: "Business",
        Code: "Code"
      }
    },
    row: {
      No: "No",
      ID: "ID",

      Create: "Create",
      Update: "Update",
      Delete: "Delete",
    },

    page: {

    },
    enum: {
      VALID: "VALID",
      INVALID: "INVALID",
      YES: "YES",
      NO: "NO"
    },
    treenode: {
      moveTo: "moveTo",
      copyTo: "copyTo",
      move: "move",
      copy: "copy",
      message: "<br>will reorder, close to cancel?",
      cancel: "abort moving",
      label: {
        Parent: "Upper",
        Short: "Short",

      }
    }
  },
  sizeSelect: {
    tooltip: "Layout Size",
    default: "Default",
    large: "Large",
    small: "Small",
    message: {
      success: "Switch Layout Size Successful!",
    },
  },
  langSelect: {
    message: {
      success: "Switch Language Successful!",
    },
  },
  settings: {
    project: "Project Settings",
    theme: "Theme",
    interface: "Interface",
    navigation: "Navigation",
    themeColor: "Theme Color",
    tagsView: "Tags View",
    fixedHeader: "Fixed Header",
    sidebarLogo: "Sidebar Logo",
    watermark: "Watermark",
  },
};

export default {
  status: {
    WORK:"WORK",
    DISCARD:"DISCARD"
  },
  product: {
    category: {
      Parent: "Parent"
    },
    Warehouse:"Warehouse",
    Stock:"Stock",
    Product: "Product"
  },
  warehouse: {
    Region:"Regional Warehouses",
    Name:"Warehouse Name",
    Code:"Warehouse Code",
    BatchNo:"Batch No.",
    InboundType:"Inbound Type",
    Quantity:"Quantity",
    InboundTime:"Inbound Time",
    Warehouse: "Warehouse",
    OrderNo:"Order No.",
    WarehouseIn: "Warehouse In",
    WarehouseOut: "Warehouse Out"
  }

}

export default {
    rule: {
        product: {
            productNo:"货号不能为空",
            unitPrice: "单价不能为空",
            unitCost: "成本价不能为空",
            category: "请选择分类",
            presales: "预售不能为空",
            warehouse: "请选择仓库"
        }
    },
    placeholder: {
        NameOrNo: "名称/货号"
    }

}
